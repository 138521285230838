<template>
  <v-dialog v-model="showing" max-width="800">
    <v-card :loading="loading">
      <v-card-title
        class="text-subtitle-1 font-weight-medium d-flex justify-space-between align-center"
      >
        Consentimentos com {{ bearerName }}
        <v-btn icon color="grey darken-2" class="mr-n2" @click="showing = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-list
        class="py-0"
        style="overflow-y: auto"
        :style="{
          'max-height': ($vuetify.breakpoint.height-62-150) + 'px',
        }"
      >
        <v-list-item-group v-for="consent in consents" :key="consent.id">
          <v-list-item :to="{name: 'consent', params: {consent_id: consent.id}}" class="px-5 py-1">
            {{ consent.consent_requests[0].purposes.join(". ") }}
            <v-spacer />
            <span class="mr-2 text-caption">
              {{ $utils.formatDate(consent.consent_requests[0].creation_datetime) }}
            </span>
            <ConsentStatus :answers="consent.consent_requests[0].answers" />
          </v-list-item>
          <v-divider />
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import ConsentStatus from "@/components/ConsentStatus.vue";

export default {
  name: "ContactConsentsDialog",

  components: { ConsentStatus },

  data: () => ({
    showing: false,
    consentDialog: false,
    bearer_id: -1,
    bearerName: "",
    consents: [],
    loading: false,
  }),

  methods: {
    open(bearer_id, bearerName) {
      this.showing = true;
      this.bearer_id = bearer_id;
      this.bearerName = bearerName;
      this.getConsents();
    },
    getConsents(){
      this.loading = true
      this.$axios.get(`/consents/${this.bearer_id}`)
      .then((response) => {
        this.consents = response.data;
      })
      .catch((error) => {
        this.$showMessage("error", "Não foi possível carregar os consentimentos")
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
};
</script>
