<template>
  <v-card elevation="4">
    <v-card-title class="d-flex align-center text-h5 font-weight-medium">
      Titulares de Dados
      <HelpDialog
        class="mx-4"
        title="Titulares de Dados"
        message="Preciso de ajuda em titulares de dados."
      >
        <p>
          Nessa página aparecem todas as pessoas com quem sua empresa já fez algum consentimento.
          Em algumas situações, enquanto os titulares ainda <b>não se cadastraram</b> no aplicativo, seus dados não aparecerão nessa página.
        </p>
      </HelpDialog>
    </v-card-title>
    <v-divider />
    <v-card-subtitle class="pa-1">
      <v-text-field
        v-model="searchQuery"
        prepend-inner-icon="mdi-magnify"
        label="Buscar"
        filled
        hide-details="auto"
        class="mt-0 pt-0"
      />
    </v-card-subtitle>
    <v-divider />
    <v-data-table
      :headers="headers"
      :items="contacts"
      :items-per-page="-1"
      :search="searchQuery"
      :loading="loading"
      loading-text="Carregando... Por favor, espere"
      hide-default-footer
      no-data-text="Não foram encontradas relações com outras pessoas"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          color="grey lighten-3" depressed
          x-small class="py-3"
          @click="$refs.consentDialog.open(item.id, item.name)"
        >
          <v-icon class="mr-1" small>mdi-book-open</v-icon>
          Ver consentimentos
        </v-btn>
      </template>
    </v-data-table>
    <v-divider />
    <ContactConsentsDialog ref="consentDialog" />    
  </v-card>
</template>

<script>
import HelpDialog from "@/components/HelpDialog.vue";
import ContactConsentsDialog from '@/components/ContactConsentsDialog.vue'

export default {
  components: {
    ContactConsentsDialog, HelpDialog,
  },
  data: () => ({
    searchQuery: "",
    loading: false,
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        //filtereable: false,
        value: "name",
      },
      { text: "Telefone", value: "phone" },
      { text: "Email", value: "email" },
      { text: "Consentimentos Realizados", value: "actions", align:"end", sortable: false },
    ],
    contacts: [],
  }),

  methods: {
    loadContacts(){
      this.loading = true
      this.$axios.get("/contacts")
      .then((response) => {
        this.contacts = response.data
      })
      .catch((error) => {
        if(error.response) {
          if(error.response.status == 422) {
            this.$showMessage("warning", "Não foi possível carregar os contatos")
            return
          }
        }
        this.$showMessage("warning", "Não foi possível carregar os contatos")
      })
      .finally(() => {
        this.loading = false;
      })
    },
  },
  mounted() {
    this.loadContacts();
  },
  
};
</script>